import React, { useMemo } from 'react';

import { View } from 'react-native';
import * as localeList from 'date-fns/locale';
import { format } from 'date-fns';

import { Button, Callout, Spacer, Tooltip, useResponsive, useTheme } from '@masteos/aphrodite';

import { RealEstateCard } from '@app/shared/components/real-estate-card/RealEstateCard';
import { useTranslation } from '@app/services/translations/translations';
import { PositioningStatus } from '@app/libs/apollo/introspection';
import { SuggestionsSourcePage } from '@app/services/tracking/constants';
import { getExpirationDateString } from '@app/utils/positioning/getExpirationDateString';

import { SuggestionCardProps } from './SuggestionCard.types';
import { getStyle } from './SuggestionCard.styles';

export const SuggestionCard: React.FC<SuggestionCardProps> = ({
  onApprovePositioning,
  onCancelPositioning,
  onNeedCallAfterConfirmPositioning,
  advisorName,
  afterConfirmCallLaunched,
  expirationDate,
  ...realEstateCardProps
}) => {
  const { isLowerThanTablet, isNativeOrResponsive } = useResponsive();
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const styles = getStyle({ isLowerThanTablet });

  const locale = localeList[i18n.resolvedLanguage];
  const { positioningStatus } = realEstateCardProps;

  const handleCancelPositioning = () => onCancelPositioning(SuggestionsSourcePage.SUGGESTIONS_PAGE);

  const handleNeedCallAfterConfirmPositioning = () =>
    onNeedCallAfterConfirmPositioning(SuggestionsSourcePage.SUGGESTIONS_PAGE);

  const InterestedButton = useMemo(() => {
    if (
      ![PositioningStatus.Recommendation, PositioningStatus.Pending].includes(
        realEstateCardProps.positioningStatus
      )
    ) {
      return null;
    }

    const handleApprovePositioning = () =>
      onApprovePositioning(SuggestionsSourcePage.SUGGESTIONS_PAGE, true);

    const positioningIsPending =
      realEstateCardProps.positioningStatus === PositioningStatus.Pending;

    const label = positioningIsPending
      ? 'suggestions.suggestionCard.advisorNotified'
      : 'suggestions.suggestionCard.actions.interested';

    return (
      <>
        <Button
          onPress={handleApprovePositioning}
          variant="primary"
          disabled={positioningIsPending}
          iconName={positioningIsPending ? null : 'Phone'}
          iconPlacement="left"
          testID="suggestion-card-actions-button-interested"
        >
          {t(label)}
        </Button>
        <Spacer height={theme.spacing.SIZE_03} />
      </>
    );
  }, [onApprovePositioning, realEstateCardProps.positioningStatus, t, theme.spacing.SIZE_03]);

  const labelForNotInterestedButton =
    realEstateCardProps.positioningStatus !== PositioningStatus.Recommendation
      ? 'suggestions.suggestionCard.actions.noLongerInterested'
      : 'suggestions.suggestionCard.actions.notInterested';

  const expirationDateText = useMemo(
    () =>
      expirationDate &&
      getExpirationDateString({
        expirationDate: new Date(expirationDate),
        locale,
        nowDate: new Date(),
        translate: t,
      }),
    [locale, expirationDate, t]
  );

  const Wrapper = !isNativeOrResponsive ? Tooltip : ({ children }) => <>{children}</>;

  return (
    <View style={styles.container} testID="suggestion-card-container">
      {expirationDateText ? (
        <Wrapper
          title={t('suggestions.expiredDate.expireAt', {
            date: format(new Date(expirationDate), 'd MMMM y', { locale }),
          })}
        >
          <Callout
            icon="Clock"
            text={expirationDateText}
            wrapperStyle={styles.callout}
            tint={theme.palette.tint.gray}
            testID="suggestion-card-callout-expired"
          />
        </Wrapper>
      ) : null}
      <RealEstateCard
        {...realEstateCardProps}
        positioningStatus={positioningStatus}
      ></RealEstateCard>
      <Spacer height={theme.spacing.SIZE_04} />
      {InterestedButton}
      {positioningStatus === PositioningStatus.Confirmed ? (
        <Button
          onPress={handleNeedCallAfterConfirmPositioning}
          variant="secondary"
          iconName="Phone"
          iconPlacement="left"
          disabled={afterConfirmCallLaunched}
          testID="suggestion-card-actions-button-needCallAfterConfirm"
        >
          {t('suggestions.suggestionCard.actions.needCallAfterConfirm', { advisorName })}
        </Button>
      ) : (
        <Button
          onPress={handleCancelPositioning}
          variant="tertiary"
          iconName="ThumbsDown"
          iconPlacement="left"
          testID="suggestion-card-actions-button-notInterested"
        >
          {t(labelForNotInterestedButton)}
        </Button>
      )}
    </View>
  );
};
