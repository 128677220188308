import { Locale, compareDesc, format, startOfDay } from 'date-fns';
import { TFunction } from 'react-i18next';

import { getDiffDays } from '@app/utils/date/getDiffDays.utils';

export const getExpirationDateString = ({
  translate,
  nowDate,
  expirationDate,
  locale,
  full = false,
}: {
  translate: TFunction<'translation', undefined>;
  nowDate: Date;
  expirationDate: Date;
  locale: Locale;
  full?: boolean;
}): string | null => {
  if (compareDesc(startOfDay(nowDate), startOfDay(expirationDate)) === -1) {
    return null;
  }

  const diffDays = getDiffDays(nowDate, expirationDate);

  if (diffDays === 0) {
    return translate('suggestions.expiredDate.today');
  }

  if (diffDays === 1) {
    return translate('suggestions.expiredDate.tomorrow');
  }

  return full
    ? translate('suggestions.expiredDate.daysWithDate', {
        date: format(expirationDate, 'd MMMM y', { locale }),
        days: diffDays,
      })
    : translate('suggestions.expiredDate.days', { days: diffDays });
};
