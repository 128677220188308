import React, { useMemo } from 'react';

import * as localeList from 'date-fns/locale';
import { View } from 'react-native';

import { Callout, Spacer, Spacing, useTheme } from '@masteos/aphrodite';

import { useTranslation } from '@app/services/translations/translations';
import { useMyProjectsList } from '@app/features/projectList/useProjectList.hook';
import { getExpirationDateString } from '@app/utils/positioning/getExpirationDateString';

import { getStyles } from './ShowCaseCallout.styles';
import { ShowCaseCalloutProps } from './ShowCaseCallout.types';
import { getPositioningCalloutList, getTransactionCalloutProps } from './ShowCaseCallout.utils';

export const ShowCaseCallout: React.FC<ShowCaseCalloutProps> = ({ property }) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const { data: { myProjects } = { myProjects: [] }, loading: loadingProjects } =
    useMyProjectsList();
  const styles = getStyles();

  const locale = localeList[i18n.resolvedLanguage];
  const positioningExpirationDate = property?.metadata?.positioning?.expirationDate;

  const list = useMemo(
    () =>
      [
        {
          id: 'callout-positioning-status',
          props:
            getPositioningCalloutList(
              t,
              theme,
              positioningExpirationDate
                ? getExpirationDateString({
                    expirationDate: new Date(positioningExpirationDate),
                    full: true,
                    locale,
                    nowDate: new Date(),
                    translate: t,
                  })
                : undefined
            )[property.metadata?.positioning?.status] || null,
        },
        {
          id: 'callout-in-transaction',
          props: getTransactionCalloutProps({ loadingProjects, projects: myProjects, property, t }),
        },
      ].filter(callout => callout.props !== null),
    [loadingProjects, locale, myProjects, positioningExpirationDate, property, t, theme]
  );

  const callouts = useMemo(
    () =>
      list.map(({ id, props }) => (
        <>
          <Callout {...props} testID={id} />
          <Spacer height={Spacing.SIZE_04} />
        </>
      )),
    [list]
  );

  return callouts ? <View style={styles.wrapper}>{callouts}</View> : null;
};
