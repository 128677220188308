import { StyleSheet, ViewStyle } from 'react-native';

import { Theme } from '@masteos/aphrodite';

type SuggestionCardListStyleType = {
  isGreaterThanTablet: boolean;
  isGreaterThanLaptop: boolean;
  theme: Theme;
};

export const getStyle = ({
  isGreaterThanTablet,
  isGreaterThanLaptop,
  theme,
}: SuggestionCardListStyleType): { container: ViewStyle } => {
  if (isGreaterThanTablet) {
    return StyleSheet.create({
      container: {
        alignItems: isGreaterThanLaptop ? 'flex-end' : 'center',
        backgroundColor: theme.palette.neutral[50],
        borderRadius: theme.borderRadius.SIZE_06,
        justifyContent: isGreaterThanLaptop ? 'center' : 'flex-start',
        paddingHorizontal: 40,
        paddingVertical: 24,
      },
    });
  }

  return StyleSheet.create({
    container: {
      alignItems: 'center',
      paddingBottom: 16,
      paddingTop: 6,
    },
  });
};
