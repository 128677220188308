import { TFunction } from 'react-i18next';

import { CalloutProps, Theme } from '@masteos/aphrodite';

import {
  GetMyProjectsQuery,
  PositioningStatus,
  PropertyStatuses,
  RealEstate,
} from '@app/libs/apollo/introspection';

export const getTransactionCalloutProps = ({
  property,
  projects,
  loadingProjects,
  t,
}: {
  property: RealEstate;
  projects: GetMyProjectsQuery['myProjects'];
  loadingProjects: boolean;
  t: TFunction<'translation', undefined>;
}): { text: string | null } => {
  if (property.status !== PropertyStatuses.Transaction || loadingProjects) {
    return null;
  }

  if (!loadingProjects && !projects.find(({ realEstate }) => realEstate.id === property.id)) {
    return {
      text: t('propertyInfo.alert.inTransactionDesc'),
    };
  }

  return {
    text: t('propertyInfo.alert.inTransactionDescUser'),
  };
};

export const getPositioningCalloutList = (
  t: TFunction<'translation', undefined>,
  theme?: Theme,
  expiredText?: string
): Partial<Record<PositioningStatus, CalloutProps>> => ({
  [PositioningStatus.Recommendation]: {
    icon: 'Clock',
    text: expiredText,
    tint: theme.palette.tint.gray,
  },
  [PositioningStatus.Pending]: { icon: 'Info', text: t('propertyInfo.positioning.Pending') },
  [PositioningStatus.Confirmed]: { icon: 'Info', text: t('propertyInfo.positioning.Confirmed') },
  [PositioningStatus.Cancelled]: {
    icon: 'HeartBroken',
    text: t('propertyInfo.positioning.Cancelled'),
  },
  [PositioningStatus.PrePositioningCancelled]: {
    icon: 'HeartBroken',
    text: t('propertyInfo.positioning.PrePositioningCancelled'),
  },
});
