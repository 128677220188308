/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useMemo } from 'react';

import { Gap, useResponsive, useTheme } from '@masteos/aphrodite';

import { SuggestionCard } from '../suggestion-card/SuggestionCard';
import { getStyle } from './SuggestionCardList.styles';
import { SuggestionCardListProps } from './SuggestionCardList.types';
import { SuggestionCardListSeparator } from './suggestion-card-list-separator/SuggestionCardListSeparator';

export const SuggestionCardList: React.FC<SuggestionCardListProps> = ({
  list,
  advisorName,
  handleApprovePositioning,
  handleNeedCallAfterConfirmPositioning,
  handleCancelPositioning,
  handleItemClick,
  handleItemBookmark,
}) => {
  const { isGreaterThanTablet, isGreaterThanLaptop } = useResponsive();
  const theme = useTheme();
  const styles = getStyle({ isGreaterThanLaptop, isGreaterThanTablet, theme });
  const isTablet = !!isGreaterThanTablet && !isGreaterThanLaptop;

  const cards = useMemo(
    () =>
      list.map(positioningItem => {
        const { realEstateItem } = positioningItem;

        return (
          <React.Fragment key={realEstateItem.id}>
            <SuggestionCard
              {...realEstateItem}
              advisorName={advisorName}
              afterConfirmCallLaunched={positioningItem.afterConfirmCallLaunched}
              onBookmark={handleItemBookmark(realEstateItem)}
              onClick={handleItemClick(realEstateItem)}
              onTooltipHover={() => {
                /**/
              }}
              onApprovePositioning={handleApprovePositioning(
                positioningItem.id,
                positioningItem.realEstateItem.id
              )}
              onCancelPositioning={handleCancelPositioning(
                positioningItem.id,
                positioningItem.isSuggestion
              )}
              onNeedCallAfterConfirmPositioning={handleNeedCallAfterConfirmPositioning(
                positioningItem.id
              )}
              positioningStatus={positioningItem.status}
              expirationDate={positioningItem.expirationDate}
            />
            {!!isTablet && <SuggestionCardListSeparator />}
          </React.Fragment>
        );
      }),
    [
      list,
      advisorName,
      handleItemBookmark,
      handleItemClick,
      handleApprovePositioning,
      handleCancelPositioning,
      handleNeedCallAfterConfirmPositioning,
      isTablet,
    ]
  );

  return (
    <Gap
      style={styles.container}
      testID="suggestion-card-list-container"
      gap={isGreaterThanLaptop ? 40 : isTablet ? 0 : 48}
      direction={isGreaterThanLaptop ? 'horizontal' : 'vertical'}
    >
      {cards}
    </Gap>
  );
};
