import { TranslationContentValue } from '@masteos/aphrodite/lib/typescript/atoms/translation-content/TranslationContent.types';

import { formatOrdinal } from '@app/services/numbersFormatter/numbersFormatter';
import { DescriptionWithImages } from '@app/types/property';
import { GetCustomerQuery, RealEstate, Role } from '@app/libs/apollo/introspection';
import { StateOfCustomer } from '@app/shared/hooks/useMe';

export interface SliderImageObject {
  url: string;
  description?: string;
  title?: string;
  translation?: TranslationContentValue;
}

const sliderImageObjectAdaptor = (
  descriptonWithImage: DescriptionWithImages,
  sectionName: string
): SliderImageObject[] => {
  return (
    descriptonWithImage.images?.map(imageUrl => ({
      description: descriptonWithImage.description,
      title: `shared.descriptionFields.${sectionName}`,
      translation: descriptonWithImage.translation,
      url: imageUrl,
    })) ?? []
  );
};

export const getHeaderSliderImages = (
  property: RealEstate,
  isLocationInformationVisible: boolean,
  stateOfCustomer: StateOfCustomer
): SliderImageObject[] => {
  if (!property || !stateOfCustomer) {
    return [];
  }

  const cover = { title: 'coverImage', url: property.coverImage };
  if (stateOfCustomer === StateOfCustomer.PRE_APPOINTMENT) {
    return [cover];
  }
  const inside = sliderImageObjectAdaptor(property.inside, 'inside');
  const outside = sliderImageObjectAdaptor(property.building.outside, 'outside');
  const common = sliderImageObjectAdaptor(property.building.common, 'common');
  const neighborhood = sliderImageObjectAdaptor(property.building.neighborhood, 'neighborhood');

  if (isLocationInformationVisible) {
    return [cover, ...inside, ...outside, ...common, ...neighborhood];
  }
  return [cover, ...inside, ...common];
};

export type VisibilityRight = 'hasMandate' | 'hasTransaction' | 'isUnlogged' | 'isInProposals';

export type Rights = Record<VisibilityRight, boolean>;

export const getVisibilityRights = (
  customer?: GetCustomerQuery['me']
): {
  canSeePositioningButton: boolean;
  canSeeLikeButton: boolean;
  canSeeLoginButton: boolean;
  canSeeMandateButton: boolean;
  canSeeAppointmentButton: boolean;
} => {
  const isAssetManagementConsultant = customer?.account.roles?.includes(
    Role.AssetManagementConsultant
  );
  const canSeeLoginButton = !customer?.id;
  const canSeeMandateButton = !!customer?.id && !customer.searchMandate?.valid;
  const canSeeAppointmentButton =
    !!customer?.id && !customer.searchMandate?.valid && !isAssetManagementConsultant;
  const canSeeLikeButton = !!customer?.id && !!customer.advisor && customer.searchMandate?.valid;
  const canSeePositioningButton = !!customer?.id && !!customer.advisor;

  return {
    canSeeAppointmentButton,
    canSeeLikeButton,
    canSeeLoginButton,
    canSeeMandateButton,
    canSeePositioningButton,
  };
};

export const propertyFloorToString = (floor: number, groundString: string): string => {
  if (floor === null) {
    return null;
  }

  if (floor === 0) {
    return groundString;
  }

  return formatOrdinal(floor);
};
