import { StyleSheet, ViewStyle } from 'react-native';

type SuggestionCardStyleType = {
  isLowerThanTablet: boolean;
};

export const getStyle = ({
  isLowerThanTablet,
}: SuggestionCardStyleType): { container: ViewStyle; callout: ViewStyle } =>
  StyleSheet.create({
    callout: { marginBottom: 12, paddingHorizontal: 12, paddingVertical: 8 },
    container: {
      flexShrink: 1,
      maxWidth: isLowerThanTablet ? '100%' : 355,
      width: '100%',
    },
  });
