import React from 'react';

import { Platform, StyleProp, StyleSheet, TextStyle, View } from 'react-native';

import {
  ActionButtonWithTip,
  Spacer,
  Spacing,
  Text,
  useResponsive,
  useTheme,
} from '@masteos/aphrodite';

import { useMe } from '@app/shared/hooks/useMe';
import { useTranslation } from '@app/services/translations/translations';
import { realEstateToFields, usePropertyTitle } from '@app/shared/hooks/usePropertyTitle';
import { hideOnPrint } from '@app/style/hideOnPrint';
import { RealEstate } from '@app/libs/apollo/introspection';
import { config } from '@app/config';
import { LINKS_MASTEOS } from '@app/constants/constants';
import { onShare } from '@app/utils/share';
import { LikePropertyActionButton } from '@app/shared/components/LikePropertyActionButton/LikePropertyActionButton';
import { trackClickToShare, TrackingOrigin } from '@app/services/tracking/trackTracking';
import { usePropertyLike } from '@app/shared/hooks/usePropertyLike';

import { ShowCaseCallout } from '../show-case/compounds/show-case-callout/ShowCaseCallout';

interface PropertyTitleProps {
  property: RealEstate;
  isAddressVisible: boolean;
  isHunterNameVisible?: boolean;
  address: string;
  currentTab: TrackingOrigin;
}

const HiddenOnPrint = hideOnPrint('div');

export const PropertyTitle: React.FC<PropertyTitleProps> = ({
  property,
  isAddressVisible,
  isHunterNameVisible = false,
  address,
  currentTab,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isLowerThanLaptop, isGreaterThanTablet, isMobile } = useResponsive();
  const { customer } = useMe();
  const title = usePropertyTitle(realEstateToFields(property));
  const baseUrl = config.isProduction ? LINKS_MASTEOS : `${config.WEB_APP_URL}/property`;
  const { onLike, onError: onPropertyError } = usePropertyLike(property, currentTab);
  const url = `${baseUrl}/${property.id}`;

  const sharingContent = { title, url };
  const styles = StyleSheet.create({
    header: { textAlign: 'center' },
    positioningCallout: {
      marginBottom: 24,
      marginHorizontal: isLowerThanLaptop ? 20 : 'unset',
      maxWidth: '100%',
    },
    shareButtonContainer: {
      flexDirection: 'row',
      position: 'absolute',
      right: theme.spacing.SIZE_00,
      zIndex: 10,
    },
    textStyles: {
      textAlign: isLowerThanLaptop ? 'center' : 'left',
      whiteSpace: 'pre-line',
    },
    title: {
      alignItems: isLowerThanLaptop ? 'center' : 'flex-start',
      justifyContent: isLowerThanLaptop ? 'center' : 'flex-start',
      marginTop: theme.spacing.SIZE_09,
    },
    wrapper: {
      flexDirection: 'column',
      padding: isLowerThanLaptop ? 12 : 0,
      width: '100%',
      zIndex: 1001,
    },
  });

  const onPressShare = async () => {
    await onShare(sharingContent, isMobile);
    trackClickToShare({ propertyId: property.id });
  };

  const shouldUseShare =
    Platform.OS === 'ios' ||
    Platform.OS === 'android' ||
    (Platform.OS === 'web' && isMobile && navigator.canShare?.(sharingContent));

  return (
    <View style={styles.wrapper} testID="property-header-component">
      <View style={styles.title}>
        <Text
          style={styles.header as StyleProp<TextStyle>}
          textStyle={isLowerThanLaptop ? 'Headline1Medium' : 'Title3Medium'}
        >
          {title}
        </Text>
      </View>
      {isAddressVisible ? (
        <>
          {!isLowerThanLaptop && <Spacer height={theme.spacing.SIZE_04} />}
          <Text
            testID="property-address-text"
            textStyle="Body2"
            style={styles.textStyles as StyleProp<TextStyle>}
          >
            {address}
          </Text>
          <Spacer height={theme.spacing.SIZE_04} />
        </>
      ) : null}
      <HiddenOnPrint>
        {isHunterNameVisible ? (
          <View>
            <Text textStyle="Body2" style={styles.textStyles as StyleProp<TextStyle>}>
              {t('propertyInfo.hunterName', property.hunter)}
            </Text>
          </View>
        ) : null}
      </HiddenOnPrint>
      <Spacer height={theme.spacing.SIZE_04} />
      {isGreaterThanTablet ? (
        <View style={styles.shareButtonContainer}>
          <ActionButtonWithTip
            tooltipText={t('shared.copiedToClipboard')}
            onPress={onPressShare}
            withSolidBorder={true}
            leftIconName={shouldUseShare ? 'Share' : 'Link'}
          />

          {!!customer?.id && (
            <>
              <Spacer width={theme.spacing.SIZE_03} />
              <LikePropertyActionButton
                realEstateId={property.id}
                size="m"
                hasBorder
                isFavorite={property.metadata.isFavorites}
                onLike={onLike}
                onError={onPropertyError}
              />
            </>
          )}
        </View>
      ) : null}
      <Spacer height={Spacing.SIZE_07} />
      <ShowCaseCallout property={property} />
    </View>
  );
};
